import React, { useState } from "react"
import HomePageLayout from "../../components/layouts/HomePageLayout"
import SEO from "../../components/seo"
import generalClasses from "./general.module.css"
import circleIcon1 from "../../images/case-studies/1-polleasy.png"
import circleIcon2 from "../../images/case-studies/2-polleasy.png"
import circleIcon3 from "../../images/case-studies/3-polleasy.png"
import polleasyOverview from "../../images/case-studies/polleasy-overview.jpg"
import polleasySolution from "../../images/case-studies/polleasy-solution.jpg"
import polleasyWebsite from "../../images/case-studies/polleasy-website.jpg"

import Footer from "../../components/footer"
import RequestModal from "../../components/requestModal"

import { Link } from "gatsby"
import arrowLeft from "../../images/arrow-left.svg"
import LeftIcon from "../../images/arrow-left.inline.svg"

const Polleasy = props => {
  const [modalOpen, toggleModalOpen] = useState(false)

  return (
    <HomePageLayout pageTitle="Polleasy" background>
      <SEO title="Polleasy Case Study" />
      <main className={generalClasses.main}>
        <Link to={"/solutions/products"} className={generalClasses.backLink}>
          <img
            src={arrowLeft}
            alt=""
            className={generalClasses.backLinkImage}
          />
          Products
        </Link>
        <section
          className={[
            generalClasses.caseStudyIntro,
            generalClasses.section,
          ].join(" ")}
        >
          <div>
            <h1 className={generalClasses.caseStudyHeader}>Polleasy</h1>
            <p className={generalClasses.caseStudyDescription}>
              Polleasy is a premier election services solution that provides
              Election Strategy Consulting, Political Survey, Opinion Poll, Exit
              Poll, Constituency Profiling, Election Management and Political
              Campaigning for Political candidates and Parties.
            </p>
            <p className={generalClasses.caseStudyCategory}>
              UI / UX | MOBILE APP DEVELOPMENT
            </p>
          </div>
          <ul className={generalClasses.caseStudyDetails}>
            <li className={generalClasses.caseStudyDetail}>
              <h4 className={generalClasses.caseStudyDetailHeader}>USERS</h4>
              <p className={generalClasses.caseStudyDetailContent}>
                1,000 - 1,000,000 Users
              </p>
            </li>
            <li className={generalClasses.caseStudyDetail}>
              <h4 className={generalClasses.caseStudyDetailHeader}>INDUSTRY</h4>
              <p className={generalClasses.caseStudyDetailContent}>
                Technology
              </p>
            </li>
          </ul>
        </section>
        <section
          className={[
            generalClasses.contentMargin,
            generalClasses.section,
          ].join(" ")}
        >
          <h1
            className={generalClasses.sectionHeader}
            style={{
              marginBottom: "40px",
            }}
          >
            MEASURABLE IMPACT
          </h1>
          <div className={generalClasses.grid3}>
            <div>
              <img
                src={circleIcon1}
                className={generalClasses.measurableImpactIcon}
                alt=""
              />
              <p className={generalClasses.sectionText}>
                Polleasy has led to over 90% accuracy in gathering and analysis
                of data on voting trend and opinion poll
              </p>
            </div>
            <div>
              <img
                src={circleIcon2}
                className={generalClasses.measurableImpactIcon}
                alt=""
              />
              <p className={generalClasses.sectionText}>
                With Polleasy, you can get 99% election forecasting accuracy
              </p>
            </div>
            <div>
              <img
                src={circleIcon3}
                className={generalClasses.measurableImpactIcon}
                alt=""
              />
              <p className={generalClasses.sectionText}>
                Over 83% reduction in time taken to conduct data analysis
              </p>
            </div>
          </div>
        </section>
        <section
          className={[
            generalClasses.imageGrid,
            generalClasses.section,
            generalClasses.imageGridLeft,
          ].join(" ")}
        >
          <img
            src={polleasyOverview}
            className={generalClasses.displayImageFull}
            alt=""
          />
          <div>
            <h1 className={generalClasses.sectionHeader}>
              overview & challenges
            </h1>
            <p className={generalClasses.sectionText}>
              Elections in major African countries is always characterized by
              violent act of thuggery, rigging, and ballot snatching. The lack
              of trust in the electoral umpire has further complicates the
              situation. The absence of demographic data makes electioneering
              process more complex and difficult to manage for the electoral
              umpire, political parties, and candidates. A careful thought
              through strategy that could be used to achieve the best result was
              a challenge, particularly in areas of forecasting and managing
              data for electoral process or specific candidacy.
            </p>
          </div>
        </section>
        <section
          className={[
            generalClasses.imageGrid,
            generalClasses.section,
            generalClasses.imageGridRight,
          ].join(" ")}
        >
          <div>
            <h1 className={generalClasses.sectionHeader}>solution</h1>
            <p className={generalClasses.sectionText}>
              Polleasy is a data-driven election strategy and management
              solution that provides the campaign team, key stakeholders and the
              political candidate access to intelligent data on popularity,
              public opinion, needs assessment, media feeds, incidences,
              electoral history and trend analysis per region/geographical
              location, in a bid to get a favorable outcome. Polleasy has been
              used for elections and data gathering in Nigeria. Recently,
              Polleasy was deployed for the All Progressive Congress (APC) in
              Kano state for the 2019 Presidential and the Gubernatorial
              election, our premier election service solution helped Kano state
              APC to be victorious in both elections because database were
              generated from voters survey was carried out by field agent prior
              to the election, analysis were carried out to know the numbers of
              registered voters per polling unit, constituency profiling was
              conducted, and call management Centre was also set up to analysis
              and addressed issues communicated by field agent. These holistic
              and deliberate processes ensures that candidate/party is abreast
              of the elections by having firsthand knowledge and information
              about their standing with the electorates, historical voting data
              and pattern analysis to show voting trends, news, media, and
              information management as well as live incidence reporting and
              results consolidation on election day.
            </p>
          </div>
          <img
            src={polleasySolution}
            className={generalClasses.displayImageFull}
            alt=""
          />
        </section>
        <img
          src={polleasyWebsite}
          alt=""
          className={generalClasses.displayImageFull}
        />
        <div
          className={generalClasses.caseStudyPageLinks}
          style={{
            paddingBottom: "85px",
          }}
        >
          <Link
            to="/case-studies/plaud"
            className={generalClasses.caseStudyPageLink}
          >
            <span className={generalClasses.caseStudyPageLinkIcon}>
              <LeftIcon
                className={generalClasses.caseStudyPageLinkIconImage}
                width="11px"
                height="18px"
              />
            </span>
            <p className={generalClasses.caseStudyPageLinkText}>
              <span className={generalClasses.caseStudyPageLinkTag}>
                PREVIOUS
              </span>
              <span className={generalClasses.caseStudyPageLinkTitle}>
                Plaud
              </span>
            </p>
          </Link>
        </div>
        <section
          className={[generalClasses.requestQuote, generalClasses.section].join(
            " "
          )}
        >
          <h1
            className={[
              generalClasses.sectionHeader,
              generalClasses.requestQuoteHeader,
            ].join(" ")}
          >
            learn more about polleasy
          </h1>
          <button
            className={generalClasses.requestQuoteButton}
            onClick={() => toggleModalOpen(true)}
          >
            REQUEST A QUOTE
          </button>
        </section>
        <RequestModal open={modalOpen} toggleOpen={toggleModalOpen} />
      </main>
      <Footer />
    </HomePageLayout>
  )
}

export default Polleasy
